:root {
  --primary-color: #6a0dad;
  --secondary-color: #ffffff;
  --accent-color: #8a2be2;
  --text-color: #333333;
  --background-color: #f5f5f5;
}

body {
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
  background-color: var(--background-color);
  color: var(--text-color);
}

.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main-content {
  flex: 1;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
  box-sizing: border-box;
}

.app-footer {
  background-color: var(--primary-color);
  color: var(--secondary-color);
  text-align: center;
  padding: 10px;
  font-size: 0.9rem;
}

/* Navbar styles (you'll need to create a separate Navbar component) */
.navbar {
  background-color: var(--primary-color);
  padding: 10px 20px;
}

.navbar ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: space-around;
}

.navbar li {
  display: inline;
}

.navbar a {
  color: var(--secondary-color);
  text-decoration: none;
  font-weight: bold;
  padding: 10px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.navbar a:hover {
  background-color: var(--accent-color);
}

/* General styles for components */
h1, h2, h3 {
  color: var(--primary-color);
}

button {
  background-color: var(--primary-color);
  color: var(--secondary-color);
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: var(--accent-color);
}

input, select, textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
}

/* Responsive design */
@media (max-width: 768px) {
  .navbar ul {
    flex-direction: column;
  }

  .navbar li {
    margin-bottom: 10px;
  }
}