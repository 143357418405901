:root {
  --primary-color: #6a0dad;
  --secondary-color: #ffffff;
  --accent-color: #8a2be2;
  --text-color: #333333;
}

body {
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
  background-color: var(--secondary-color);
  color: var(--text-color);
}

.homepage-container {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(135deg, var(--primary-color), var(--accent-color));
}

.content-wrapper {
  background-color: var(--secondary-color);
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  display: flex;
  max-width: 1200px;
  width: 90%;
  overflow: hidden;
}

.text-section {
  flex: 1;
  padding: 60px;
}

h1 {
  font-size: 2.5rem;
  color: var(--primary-color);
  margin-bottom: 20px;
}

.subtitle {
  font-size: 1.2rem;
  margin-bottom: 30px;
}

.get-started-button {
  background-color: var(--primary-color);
  color: var(--secondary-color);
  border: none;
  padding: 15px 30px;
  font-size: 1.1rem;
  border-radius: 50px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.get-started-button:hover {
  background-color: var(--accent-color);
}

.info-section {
  margin-top: 40px;
}

h2 {
  font-size: 1.8rem;
  color: var(--primary-color);
  margin-bottom: 15px;
}

ul {
  list-style-type: none;
  padding-left: 0;
}

li {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

li::before {
  content: '✓';
  color: var(--primary-color);
  font-weight: bold;
  margin-right: 10px;
}

.icon-section {
  background-color: var(--primary-color);
  padding: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.icon-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
  color: var(--secondary-color);
}

.icon {
  font-size: 3rem;
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  .content-wrapper {
    flex-direction: column;
  }

  .text-section,
  .icon-section {
    padding: 40px;
  }
}