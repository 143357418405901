nav {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #333;
    padding: 10px;
    box-sizing: border-box;
  }
  
  ul {
    display: flex;
    justify-content: space-between;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  li {
    margin: 0 10px;
  }
  
  a {
    color: #fff;
    text-decoration: none;
  }
  