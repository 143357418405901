.escrow-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background-color: var(--secondary-color);
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.escrow-page h1 {
  color: var(--primary-color);
  text-align: center;
  margin-bottom: 30px;
}

.escrow-forms {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}

.escrow-form {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.escrow-form h2 {
  color: var(--primary-color);
  margin-bottom: 20px;
  font-size: 1.2rem;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  color: var(--text-color);
}

.form-group input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
}

button {
  background-color: var(--primary-color);
  color: var(--secondary-color);
  border: none;
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: var(--accent-color);
}

.loading {
  text-align: center;
  color: var(--primary-color);
  font-size: 1.2rem;
  margin-top: 50px;
}

@media (max-width: 768px) {
  .escrow-forms {
    grid-template-columns: 1fr;
  }
}